import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CaseStudies.css';
import './Overview.css';

const WebinarPageBg = () => {
  const [submitted, setSubmitted] = useState(false);
  const formId = 'rlv-banner-bf4ad83b-94e5-4415-8e95-4929b04bfde2';
  const buttonClass = 'releva-send-subscription';

  useEffect(() => {
    const handleButtonClick = () => {
      console.log('🔘 Submit button clicked!');
      setSubmitted(true);

      // Simulate a delay for form submission processing
      setTimeout(() => {
        const webinarForm = document.querySelector('.webinarForm');
        if (webinarForm) {
          webinarForm.reset(); // Properly resets the form instead of clearing the inner HTML
          console.log('🗑️ Cleared .webinarForm');
        }

        // Remove Google Calendar button
        const calendarButton = document.querySelector('a[target="_blank"][href*="calendar.google.com"]');
        if (calendarButton) {
          calendarButton.remove();
          console.log('🗑️ Removed Google Calendar button');
        }
      }, 1000); // Adjust the delay as needed
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const form = document.getElementById(formId);
          const button = document.querySelector(`.${buttonClass}`);

          if (form && !form.dataset.formDetected) {
            console.log('🎉 Form appeared in the DOM!');
            form.dataset.formDetected = 'true';
          }

          if (button && !button.dataset.listenerAdded) {
            button.addEventListener('click', handleButtonClick);
            button.dataset.listenerAdded = 'true';
          }
        }
      });
    });

    const targetNode = document.body;
    observer.observe(targetNode, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);
  return (
    <div className="overview solutions webinar">
      <Helmet>
        <title>Releva AI | Webinar</title>
        <meta
          name="description"
          content="Explore Releva AI's comprehensive overview. Learn how our AI-driven marketing platform can transform your business with personalized customer experiences, data-driven insights, and automated campaigns. Discover the future of marketing with Releva AI."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.solutionsPets)}</script>
      </Helmet>
      <div className="solutionsHero">
        <HomePageHeroTitle
          heroTitle="35% Предимство в Приходите: Защо Автономната 1:1 Персонализация Надминава Традиционния Дигитален Маркетинг"
          heroDescription="<p><b>Открийте революционния пробив в AI-задвижваната персонализация, който води до безпрецедентен ръст на приходите в различни индустрии.</b></p>
<p>В този ексклузивен уебинар Releva ще разкрие как автономната 1:1 персонализация фундаментално трансформира резултатите в дигиталния маркетинг. Докато традиционната персонализация обещава, но не успява да постигне истинска индивидуализация вече повече от две десетилетия, нашият революционен подход последователно генерира 35% ръст на приходите за компании от всякакъв мащаб.</p>
<p><b>Ще научите:</b></p>
<ul>
    
<li>Защо традиционната персонализация, базирана на сегменти, е достигнала своя лимит на ефективност</li>
<li>Как нашите AI агенти създават напълно индивидуални клиентски пътувания без необходимост от мащабни технически ресурси</li>
<li>Тайната зад 1600% ръст в ангажираността и 35% увеличение на приходите</li>
<li>Реални примери от компании, внедрили автономна персонализация</li>
<li>Практична рамка за оценка на потенциалното въздействие върху приходите на вашия бизнес</li>
</ul>
<p>Това не е просто поредното малко подобрение в дигиталния маркетинг – това е фундаментална промяна в начина, по който работи персонализацията. Присъединете се, за да разберете защо традиционните платформи не могат да постигнат тези резултати и как вашият бизнес може да се възползва от това значително предимство в приходите.</p>
<p><b>За кого е този уебинар:</b>: Лидери в дигиталния маркетинг, CMO, директори на e-commerce и стратези за растеж, които искат да преодолеят ограниченията на традиционния маркетинг и да постигнат съществен ръст на приходите.</p>
<p><b>Дата</b>: 10 април 2025 г. (четвъртък) 15:00 ч. (българско време)</p>
<p><b>Продължителност</b>: 45 минути + 15 минути Q&A</p>
"
          heroYoutube={false}
          heroLink="false"
        />
        <section className="symbol-library">
          <div className="webinarForm">
            {submitted && (
              <>
                <p
                  style={{
                    lineHeight: '2em',
                    padding: '0 15px',
                  }}
                >
                  Благодарим ви, че се записахте за нашия ексклузивен уебинар! Бъдете една крачка напред, като добавите
                  събитието в календара си още сега. Просто кликнете &nbsp;
                  <a
                    target="_blank"
                    href="https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=MHRuc2tnam9naWJqbTFob2UzcGI0bWZnNWMgY185YWExYjUyN2FkNjU3NGQzZjY3N2U4YjkxZWMwZDMzNGI3NDIwMzAxNWE5NjQ0MWU1ZjFjYzQyOTYyYjhjZDcwQGc&tmsrc=c_9aa1b527ad6574d3f677e8b91ec0d334b74203015a96441e5f1cc42962b8cd70%40group.calendar.google.com"
                  >
                    <img
                      border="0"
                      src="https://calendar.google.com/calendar/images/ext/gc_button1_en.gif"
                      alt="Google Calendar"
                    />
                  </a>
                  &nbsp;, за да не го пропуснете!
                </p>
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default WebinarPageBg;
