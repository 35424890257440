import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import HomePageHeroTitle from '../components/HomePage/HomePageHeroTitle';
import TrustedCustomers from '../components/TrustedCustomers';
import contentLibraryItems from '../constants/contentLibraryItems';
import pagesJsonLD from '../constants/pagesJsonLD';
import './CaseStudies.css';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ContentLibraryItem = () => {
  const query = useQuery();
  const tempUrl = useLocation();
  const itemUrl = query.get('item');
  const getContentItemByUrl = (url) => {
    return contentLibraryItems.find((item) => item.url === url);
  };
  const item = getContentItemByUrl(itemUrl);

  if (!item) {
    return (
      <div className="holder notFoundPage">
        <img src="/notFoundImage.png" alt="" />
        <div className="contentNotFound">
          <h1>404</h1>
          <p>even AI can't help you find what you are looking for...</p>
          <a href="/" className="button-name5">
            Go back home
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="case-studies contentListing">
      <Helmet>
        <title>Releva AI | Content Library</title>
        <meta
          name="description"
          content="Discover how Releva.ai leverages advanced AI to automate and optimize your e-commerce operations, driving growth, efficiency, and personalized customer experiences."
        />
        <script type="application/ld+json">{JSON.stringify(pagesJsonLD.contentLibraryPage)}</script>
      </Helmet>
      <div className={`${item.toc && Array.isArray(item.toc) && item.toc.length > 0 ? 'TOC-navigation' : 'd-none'}`}>
        <h4>Tables of content:</h4>
        <ul>
          {Array.isArray(item.toc) &&
            item.toc.map((toc) => (
              <li key={toc.id}>
                <a href={`${tempUrl.pathname}${tempUrl.search}#${toc.id}`}>{toc.title}</a>
              </li>
            ))}
        </ul>
      </div>
      <div className={`${item.toc && Array.isArray(item.toc) && item.toc.length > 0 ? 'content-with-toc' : ''}`}>
        <HomePageHeroTitle heroTitle={item.heroTitle} heroDescription={item.heroDescription} />
        <div dangerouslySetInnerHTML={{ __html: item.content }} className="contentLibraryContent" />
      </div>

      <TrustedCustomers />
    </div>
  );
};

export default ContentLibraryItem;
